import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import AccountOnboardingHeader from '../../components/AccountOnboardingHeader';
import AccountOnboardingGreeting, { LOGIN } from '../../components/AccountOnboardingGreeting';
import LoginForm from '../../components/LoginForm';
import s from './Login.module.scss';
import withStyles from '../../core/isomorphic-style-loader/withStyles';
import { Col, Row, Alert } from 'antd';
import withViewport from '../../components/withViewport';
import { safeGet } from '../../core/util/object';
import { emptyFunction } from '../../core/util/function';
import { trackIfLoadedInIframe } from '../../core/util/tracking';
import LatestUpdatesFeed from '../../components/LatestUpdatesFeed';
import styled from 'styled-components';

const PageContentContainerRow = styled(Row)`
  height: 100vh;
  padding-top: 60px;
`;

const MessageCol = styled(Col)`
  background-color: #f7f9fa;
  height: 100%;
  padding-top: 46px;
`;

const FormCol = styled(Col)`
  padding: 46px 84px;
`;

const FormHeaderRow = styled(Row)`
  color: #313131;
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 18px;
  text-align: left;
`;

class Login extends PureComponent {
  componentDidMount() {
    trackIfLoadedInIframe();
  }

  render = () => {
    const { isComingFromAccountOnboarding, isComingFromResetPassword, login, redirectUrl } =
      this.props;

    return (
      <Row>
        <AccountOnboardingHeader hideLogin />
        <PageContentContainerRow>
          <MessageCol xs={0} sm={0} md={12} xl={16}>
            <LatestUpdatesFeed
              renderContainer={({ content }) => <Col>{content}</Col>}
              renderFallback={() => (
                <AccountOnboardingGreeting
                  case={LOGIN}
                  isComingFromAccountOnboarding={isComingFromAccountOnboarding}
                  isComingFromResetPassword={isComingFromResetPassword}
                />
              )}
            />
          </MessageCol>
          <FormCol md={12} xl={8}>
            <FormHeaderRow>Log In</FormHeaderRow>
            {safeGet(login)('passwordResetRequested') && (
              <Alert
                message="Check your email"
                description="Follow the link to reset your password."
                type="info"
                closable
                showIcon
                onClose={emptyFunction}
              />
            )}
            <LoginForm redirectUrl={redirectUrl} />{' '}
          </FormCol>
        </PageContentContainerRow>
      </Row>
    );
  };
}

Login.contextTypes = {
  setTitle: PropTypes.func,
};

Login.propTypes = {
  isComingFromAccountOnboarding: PropTypes.bool,
  isComingFromResetPassword: PropTypes.bool,
  persistentStateLoaded: PropTypes.bool,
  redirectUrl: PropTypes.string,
  viewport: PropTypes.object,
};

export default withViewport(withStyles(s)(Login));
