import { useCallback, useLayoutEffect, useRef } from 'react';

/**
 * useEvent is an alternative to `useCallback()` that doesn't need a dependency array.
 * Instead, it uses a ref & some effects behind the scenes to ensure the callback is
 * always referencing up-to-date data.
 *
 * BUT! It should NEVER BE CALLED in a render method. Put in other words, ONLY CALL
 * EVENTS INSIDE `useEffect()` as well as `onChange()`, `onClick()`, etc. handlers.
 *
 * If you call a `useEvent()` callback within JSX-rendering logic, your code is near-guaranteed
 * to not be compatible with future versions of React such as concurrent rendering, suspense, etc.
 */
export const useEvent = <Func extends (...args: any[]) => any>(handler: Func): Func => {
  const handlerRef = useRef<Func>((() => {}) as any);

  useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  return useCallback((...args: any[]) => {
    const fn = handlerRef.current;
    return fn(...args);
  }, []) as any;
};
