import { Spin } from 'antd';
import moment from 'moment-timezone';
import React, { CSSProperties, useEffect } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { useBooleanFlag } from '../../core/flags/flags';
import { useRecentUpdatesTracking } from '../../core/analytics/neoEvents';
import { isLiveStage, isProd } from '../../config';
import { urlIsValid } from '../../core/url';
import { log } from '../../core/logger/log';
import { colors } from '../../constants/colors';

const SpinContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

const ScrollBox = styled.section`
  height: 100%;
  width: 100%;
  overflow-y: auto;

  padding-left: 24px;
  padding-right: 24px;
`;

const Content = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const WelcomeBackTitle = styled.h3`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 8px;
`;

const LatestUpdatesFeedSubtitle = styled.h4`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.grayMatter};
  margin-bottom: 24px;
`;

interface LatestUpdatesJsonShape {
  updates: UpdateJsonEntry[];
}

/**
 * Confluence doc: https://solvhealth.atlassian.net/l/cp/9ofEC9Rq
 */
interface UpdateJsonEntry {
  /**
   * The title of the card. The card will not display without this.
   */
  title: string;
  /**
   * The optional tracking title, if provided, will be passed to the
   * "click link" tracked event instead of the title. Useful primarily
   * if the title is generic and likely to be redundant with a prior
   * title used in the past.
   */
  trackingTitle?: string;
  /**
   * The optional description of the card.
   */
  description?: string;
  /**
   * The optional linkUrl. If this is provided, a "View details" hyperlink
   * will be displayed at the bottom of the card.
   */
  linkUrl?: string;
  /**
   * imageS3Filename, if provided, MUST be the name of an image file that has been uploaded to
   * the same S3 bucket & folder as the updates.json file. Put in other words, there must
   * be a "neighbor" or "sibling" file in the same folder as updates.json with this provided name.
   * If both imageS3Filename and imageUrl, imageS3Filename is ignored in favor of imageUrl.
   */
  imageS3Filename?: string;
  /**
   * imageUrl, if provided, MUST be the full URL of an image file. If both imageS3Filename
   * and imageUrl, imageS3Filename is ignored in favor of imageUrl. Ideally this should be the URL
   * of a Solv S3 resource (so we can guarantee the image won't suddenly disappear) but technically
   * this can be a URL to any image publically visible on the internet. The filename should include the
   * file extension.
   *
   * Example value: 'solv-logo.png'
   */
  imageUrl?: string;
  /**
   * hideOnOrAfterDate, if provided, MUST be a date in the YYYY-MM-DD format. If provided, this
   * will cause the card to NOT display if the user is viewing the page on or after the date specified.
   * In effect, it auto-hides the card
   *
   * Example value: "2023-03-21" which would auto-hide the card on March 21st.
   */
  hideOnOrAfterDate?: string;
}

interface LatestUpdatesFeedProps {
  renderFallback?: () => React.ReactNode;
  renderContainer?: (args: { content: React.ReactNode }) => React.ReactNode;
  style?: CSSProperties;
}

export default function LatestUpdatesFeed({
  renderContainer = ({ content }) => content,
  renderFallback,
  style,
}: LatestUpdatesFeedProps) {
  const trackEvent = useRecentUpdatesTracking({});

  const updatesFeedEnabled = !useBooleanFlag('hide-latest-updates-feed-on-manage');

  const { isLoading, data: updates } = useQuery(
    'fetch-latest-updates-json',
    async ({ signal }) => {
      const result: LatestUpdatesJsonShape = await getLatestUpdatesJson({ signal });

      const timezone = moment.tz.guess();
      const now = moment.tz(timezone);

      return result.updates
        .filter(
          (update) =>
            !!update &&
            typeof update === 'object' &&
            'title' in update &&
            typeof update.title === 'string' &&
            !!update.title.trim()
        )
        .map((update): UpdateJsonEntry => {
          return {
            // We confirmed title was non-null in the prior filter
            title: processStr(update.title)!,
            description: processStr(update.description),
            hideOnOrAfterDate: processStr(update.hideOnOrAfterDate),
            imageS3Filename: processStr(update.imageS3Filename),
            imageUrl: processStr(update.imageUrl),
            linkUrl: processStr(update.linkUrl),
            trackingTitle: processStr(update.trackingTitle),
          };
        })
        .filter((update) => {
          if (!update.hideOnOrAfterDate) {
            // If the hide-after field is undefined or missing, then the update is always
            // included in the output.
            return true;
          }

          const hideOnOrAfterDate = moment.tz(update.hideOnOrAfterDate, 'YYYY-MM-DD', timezone);

          if (!hideOnOrAfterDate.isValid()) {
            return false;
          }

          return now.isBefore(hideOnOrAfterDate);
        });
    },
    {
      enabled: updatesFeedEnabled,
      onSuccess(data) {
        trackEvent('success fetching', {
          allVisibleUpdates: data.map(getTrackingTitle),
        });
        if (data.length === 0) {
          trackEvent('no updates to display');
        }
      },
      onError() {
        trackEvent('error fetching');
      },
    }
  );

  useEffect(() => {
    trackEvent('load');
  }, [trackEvent]);

  useEffect(() => {
    if (!updatesFeedEnabled) {
      trackEvent('feature disabled');
    }
  }, [trackEvent, updatesFeedEnabled]);

  if (isLoading) {
    return renderContainer({
      content: (
        <SpinContainer>
          <Spin size="large" />
        </SpinContainer>
      ),
    });
  }

  if (!updatesFeedEnabled || updates == null || updates.length === 0) {
    return renderFallback?.() ?? null;
  }

  return renderContainer({
    content: (
      <ScrollBox style={style}>
        <Content>
          <WelcomeBackTitle>Welcome back!</WelcomeBackTitle>
          <LatestUpdatesFeedSubtitle>Here's what you missed</LatestUpdatesFeedSubtitle>
          {updates.map((update) => {
            let resolvedImageUrl: string | undefined = undefined;
            if (urlIsValid(update.imageUrl)) {
              resolvedImageUrl = update.imageUrl!;
            } else if (update.imageS3Filename) {
              resolvedImageUrl = deriveLatestUpdatesS3Url(update.imageS3Filename);
            }

            const trackingTitle = getTrackingTitle(update);

            return (
              <LatestUpdateCard
                key={trackingTitle}
                {...update}
                imageUrl={resolvedImageUrl}
                onClickLink={() => {
                  trackEvent('click link', {
                    title: trackingTitle,
                    link: update.linkUrl,
                    allVisibleUpdates: updates.map(getTrackingTitle),
                  });
                }}
              />
            );
          })}
        </Content>
      </ScrollBox>
    ),
  });
}

const UpdateBox = styled.div`
  width: min(100%, 472px);
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  & + & {
    margin-top: 16px;
  }
`;

const UpdateBoxRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
`;

const UpdateImageBox = styled.div`
  aspect-ratio: 1/1;
  height: 100%;
  max-width: 80px;
  overflow: hidden;
  margin-right: 16px;
`;

const UpdateImg = styled.img`
  object-fit: contain;
  height: 100%;
  aspect-ratio: 1/1;
`;

const UpdateContent = styled.div<{ center?: boolean }>`
  flex-grow: 1;

  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
`;

const UpdateTitle = styled.h4`
  font-weight: bold;
  font-size: 14x;
`;

const UpdateDescription = styled.div`
  margin-top: 4px;
`;

const UpdateLink = styled.a`
  font-weight: 450;
  font-size: 12px;
  text-decoration-line: underline;
  margin-top: 16px;
  display: block;
`;

interface LatestUpdateCardProps {
  title: string;
  description?: string | null;
  linkUrl?: string | null;
  imageUrl?: string | null;
  onClickLink(): void;
}
function LatestUpdateCard({
  title,
  description,
  linkUrl,
  imageUrl,
  onClickLink,
}: LatestUpdateCardProps) {
  return (
    <UpdateBox>
      <UpdateBoxRow>
        {!!imageUrl && (
          <UpdateImageBox>
            <UpdateImg src={imageUrl} />
          </UpdateImageBox>
        )}
        <UpdateContent center={!description}>
          <UpdateTitle>{title}</UpdateTitle>
          {!!description && <UpdateDescription>{description}</UpdateDescription>}
          {!!linkUrl && (
            <UpdateLink
              href={linkUrl}
              target="_blank"
              onClick={() => {
                // Call the callback within a callback so that the
                // passed-in callback cannot e.preventDefault();
                onClickLink();
              }}
            >
              View details
            </UpdateLink>
          )}
        </UpdateContent>
      </UpdateBoxRow>
    </UpdateBox>
  );
}

const processStr = (maybeStr: any) => (typeof maybeStr === 'string' ? maybeStr.trim() : undefined);
const getTrackingTitle = (update: UpdateJsonEntry) =>
  update.trackingTitle?.trim() || update.title?.trim();
const deriveLatestUpdatesS3Url = (fileName: string) => {
  const prefix = 'https://solv-recent-updates-feed.s3.amazonaws.com';
  if (isProd()) {
    return `${prefix}/prod/${fileName}`;
  } else {
    return `${prefix}/livestage/${fileName}`;
  }
};
const getLatestUpdatesJson = async (args: {
  signal?: AbortSignal;
}): Promise<LatestUpdatesJsonShape> => {
  if (!(isProd() || isLiveStage())) {
    const importedUpdates = await import('./localTestLatestUpdates.json');
    const result: LatestUpdatesJsonShape = importedUpdates.default;
    return result;
  }

  const url = deriveLatestUpdatesS3Url('updates.json');
  let resultJson: any;
  try {
    const result = await fetch(url, {
      signal: args.signal,
      method: 'get',
      redirect: 'follow',
    });
    resultJson = await result.json();
  } catch (err) {
    log.error(err);
    return {
      updates: [],
    };
  }

  if (!(resultJson && typeof resultJson === 'object' && Array.isArray(resultJson.updates))) {
    log.error('Invalid shape of updates.json object');
    return {
      updates: [],
    };
  }

  return resultJson as LatestUpdatesJsonShape;
};
