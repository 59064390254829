import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getLoginUrl } from '../../core/dapi/login';
import { LoginErrorMessages } from '../../constants';
import { Row, Col, Alert } from 'antd';
import { BUTTON_TYPE_SUBMIT } from '../primitives/constants';
import { SingleSignOn } from '../SingleSignOn/SingleSignOn';
import { AfterLoginOpts, onLoginSuccess } from '../../ducks/login';
import { HttpError, httpPostJson } from '../../core/http/http';
import { DAPI_CLIENT_ID, DAPI_CLIENT_SECRET } from '../../config';
import { LoginToken } from '../../types/RootState';
import { useSolvForm } from '../../core/form/useSolvForm';
import { FormProvider } from 'react-hook-form';
import { SolvInput } from '../../core/form/antd/SolvInput';
import { PasswordResetModal } from './PasswordResetModal';
import { useDispatch } from 'react-redux';
import { Button } from '../primitives';
import { useMutation } from 'react-query';
import { getAuth } from '../../core/auth';
import { useGlobalLoginEvent } from '../../core/auth/global';

type Form = {
  username: string;
  password: string;
};

const useAutoLoginWhenTokenSet = (disabled: boolean, opts?: AfterLoginOpts) => {
  const dispatch = useDispatch();
  const processing = useRef<boolean>(false);

  // If they land on the login page but already have the token set, just redirect them
  useEffect(() => {
    if (disabled || processing.current) {
      return;
    }
    const auth = getAuth();
    if (auth) {
      onLoginSuccess(dispatch, auth, opts).catch();
    }
  }, [disabled, dispatch, opts]);

  useGlobalLoginEvent(() => {
    if (disabled || processing.current) {
      return;
    }
    // dapi auth key was set, probably from another tab logging in, lets log this one back in too
    const auth = getAuth();
    if (auth) {
      processing.current = true;
      onLoginSuccess(dispatch, auth, opts)
        .catch()
        .finally(() => {
          processing.current = false;
        });
    }
  });
};

const useSubmitLogin = (opts?: AfterLoginOpts) => {
  const dispatch = useDispatch();
  return useMutation<void, HttpError | Error, Form>(async (form) => {
    const { data: token } = await httpPostJson<{ data: LoginToken }>(getLoginUrl(), {
      client_id: DAPI_CLIENT_ID,
      client_secret: DAPI_CLIENT_SECRET,
      username: form.username,
      password: form.password,
      grant_type: 'clinic',
    });
    await onLoginSuccess(dispatch, token, opts);
  });
};

export default function LoginForm(opts: AfterLoginOpts) {
  const form = useSolvForm<Form>();
  const [showResetModal, setShowResetModal] = useState(false);

  const { mutate, isLoading, error } = useSubmitLogin({
    ...opts,
    clearRemixCache: true,
  });

  useAutoLoginWhenTokenSet(isLoading, opts);

  const handleSubmit = form.handleSubmit((form) => {
    mutate(form);
  });

  const formattedError = useMemo(() => {
    if (!error) {
      return undefined;
    }
    if (error instanceof HttpError) {
      const formatted = error.formatted();
      if (formatted === 'Invalid username or password.') {
        return LoginErrorMessages.BAD_CREDENTIALS;
      }
    }
    return 'message' in error ? error.message : LoginErrorMessages.GENERIC;
  }, [error]);

  return (
    <div>
      {formattedError && (
        <Alert
          type={'error'}
          message={'We were unable to log you in.'}
          description={formattedError}
        />
      )}
      <FormProvider {...form}>
        <form className="login-form" onSubmit={handleSubmit}>
          <div>
            <SolvInput
              name={'username'}
              label={'Username'}
              data-testid="loginUsername"
              required={true}
            />
            <SolvInput
              name={'password'}
              label={'Password'}
              type="password"
              data-testid="loginPassword"
              required={true}
            />
            <Button htmlType={BUTTON_TYPE_SUBMIT} loading={isLoading} isWidthOfParent={true}>
              Log in
            </Button>
          </div>
          <Row type="flex" justify="end" style={{ marginTop: '10px' }}>
            <Col>
              <a onClick={() => setShowResetModal(true)}>forgot password?</a>
            </Col>
          </Row>
          <SingleSignOn />
          {showResetModal && <PasswordResetModal onClose={() => setShowResetModal(false)} />}
        </form>
      </FormProvider>
    </div>
  );
}
