import styled from 'styled-components';
import { ControllerProps } from 'react-hook-form';
import React, { useMemo } from 'react';
import omit from 'lodash/omit';

export const FormElement = styled.div`
  margin-bottom: 16px;
`;

export type LabelValue = { label: string; value: any };

export type CommonFieldProps = {
  required?: boolean;
  name: string;
  label?: any;
  helperText?: any;
  warning?: any;
  missing?: any;
  testId?: string;
} & Partial<ControllerProps>;

export function useFixFieldProps<P extends Partial<ControllerProps> & { required?: boolean }>(
  props: P
): Omit<P, 'name' | 'error'> & { required?: boolean } {
  return useMemo(() => {
    const copy = { ...props };
    if (copy.required != null && copy.rules?.required == null) {
      copy.rules = copy.rules || {};
      copy.rules.required = copy.required;
    }
    if (copy.rules?.required === true || copy.rules?.required) {
      if (typeof copy.rules.required === 'string') {
        copy.required = !!copy.rules.required;
      } else if (typeof copy.rules.required === 'object' && 'value' in copy.rules.required) {
        copy.required = copy.rules.required.value;
      }
    }
    return omit(copy, 'onBlur', 'onChange') as typeof copy;
  }, [props]);
}
