import { useMemo } from 'react';
import { analyticsTrackEvent } from '.';
import { useEvent } from '../../hooks/useEvent';
import {
  getIsSolvEmployee,
  getLogin,
  getUserAccountId,
  getUserGroups,
  getUserPermissions,
  getUserRoles,
} from '../../selectors/login';
import { useSelector } from '../../types/Redux';
import { isLoggedIn } from '../session';
import { GenericObject } from '../util/generics';
import { convertCamelToSnakeCaseRecursive } from '../util/object';

/**
 * The event should function as a kind of namespace for specific forms and
 * UI components in the interface. Individual interactions should be ideally
 * classified as "actions" beneath some more general UI-namespace.
 *
 * Example of good namespace: "XYZ modal", "location select"
 *            bad namespaces: "clicked button", "submit XYZ form"
 *
 * Additionally, the tracking system feeds into the same universe as all of
 * mapp's tracking, so it's best to prefix namespaces with "manage - " to help
 * with disambiguating.
 */
enum EventNamespace {
  QuickRepliesModal = 'Manage - Quick Replies Modal',
  ConfigureMessageTemplates = 'Manage - Configure Message Templates',
  CopyMessageTemplatesModal = 'Manage - Copy Message Templates Modal',
  LocationSearchModal = 'Manage - Location Search Modal',
  Settings = 'Manage - Settings',
  Queue = 'Manage - Queue',
  QueueSearch = 'Manage - Queue - Search',
  FacesheetModal = 'Manage - Facesheet Modal',
  Patients = 'Manage - Patients',
  Payments = 'Manage - Payments',
  Reviews = 'Manage - Reviews Moderation',
  UserEmailNotifications = 'Manage - User Email Notifications',
  RecentUpdatesFeed = 'Manage - Recent Updates Feed',
}

function useCommonAnalyticsProperties() {
  const loggedIn = useSelector((s) => isLoggedIn(getLogin(s)));
  const isEmployee = useSelector(getIsSolvEmployee);
  const roles = useSelector(getUserRoles);
  const permissions = useSelector(getUserPermissions);
  const accountId = useSelector(getUserAccountId);
  const groupIds = useSelector(getUserGroups);

  return useMemo(
    () => ({
      accountId,
      isLoggedIn: loggedIn,
      isSolvEmployee: isEmployee,
      roles,
      permissions,
      groupIds,
    }),
    [accountId, isEmployee, loggedIn, roles, permissions, groupIds]
  );
}

/**
 * Helper function for creating component/page-specific tracking hooks.
 * Takes three type paramters:
 *
 *    `Namespace`: the specific `EventNamespace` that tracking for the component is scoped under.
 *
 *    `Action`: a union of string constants that enumerates the kinds of interactions a user can
 *              have with the component. Avoid adding new actions to a namespace unless/until
 *              Product team asks for them. That said, most namespaces will likely have a "load"
 *              action to capture when the user first sees the relevant component/page.
 *
 *    `ReqHookProps`: list any properties here that MUST be specified whenever you call the
 *                    hook (that is, the hook that you're actively creating by calling
 *                    `createUseNamespaceTrackingHook()`). These properties are automatically included
 *                    with all payloads that are tracked by the hook. The vast majority of page-related
 *                    hooks will probably list a `locationId` or `locationIds` property here, depending
 *                    on whether you are in a multi-location (e.g. the queue) or single-location
 *                    (e.g. location-settings) context.
 */
function createUseNamespaceTrackingHook<
  Namespace extends EventNamespace,
  Action extends string,
  ReqHookProps extends Record<string, any> = {}
>(namespace: Namespace) {
  return function useTrackNamespace<HookProps extends ReqHookProps>(properties: HookProps) {
    const commonProperties = useCommonAnalyticsProperties();
    const hookProperties = properties;

    const trackAction = useEvent(function trackEventWithNamespaceAndAction(
      action: Action,
      properties?: Record<string, any>
    ) {
      const funcProperties = properties;
      analyticsTrackEvent(
        namespace,
        // Product team indicated that they prefer snake_case in mixpanel.
        convertCamelToSnakeCaseRecursive({
          ...commonProperties,
          ...hookProperties,
          ...funcProperties,
          action,
        }) as GenericObject
      );
    });

    return trackAction;
  };
}

/**
 * Annotated as an example use of `createUseNamespaceTrackingHook()`.
 *
 * `useConfigureMessageTemplatesTracking()` tracks interactions with the custom-quick-replies-settings
 * section of the location-settings page. Accordingly, it has its own namespace/scope
 * (`EventNamespace.ConfigureMessageTemplates`) and its own list of interactions ("load", "reset",
 * "save", etc.) as well as its own mandatory list of properties (locationId).
 *
 * Calling `useConfigureMessageTemplatesTracking()` in the context of a component returns a function
 * `trackAction()` that can be used to track interactions with the custom-quick-replies-settings section
 * of the location-settings page.
 */
export const useConfigureMessageTemplatesTracking = createUseNamespaceTrackingHook<
  EventNamespace.ConfigureMessageTemplates,
  | 'load'
  | 'reset'
  | 'save'
  | 'change name'
  | 'change message'
  | 'copy template variable'
  | 'open Copy Message Templates Modal',
  { locationId: string }
>(EventNamespace.ConfigureMessageTemplates);

export const useQuickRepliesModalTracking = createUseNamespaceTrackingHook<
  EventNamespace.QuickRepliesModal,
  'load' | 'select quick reply' | 'send quick reply',
  {
    locationId: string;
    selectedQuickReplyIsCustom: boolean;
    selectedStdQuickReply?: string | null;
    selectedCustomQuickReply?: string | null;
  }
>(EventNamespace.QuickRepliesModal);

export const useLocationSearchModalTracking = createUseNamespaceTrackingHook<
  EventNamespace.LocationSearchModal,
  'load' | 'filter',
  { locationId: string; filter: string }
>(EventNamespace.LocationSearchModal);

export const useCopyMessageTemplatesModalTracking = createUseNamespaceTrackingHook<
  EventNamespace.CopyMessageTemplatesModal,
  'load' | 'select template ranking' | 'select location' | 'submit' | 'cancel',
  { locationId: string; ranking?: number | null }
>(EventNamespace.CopyMessageTemplatesModal);

export const useQueueTracking = createUseNamespaceTrackingHook<
  EventNamespace.Queue,
  'open book followup from completed booking row',
  {
    locationIds: string[];
  }
>(EventNamespace.Queue);

export const useQueueSearchTracking = createUseNamespaceTrackingHook<
  EventNamespace.QueueSearch,
  'search',
  {
    locationIds: string[];
    searchFilter?: 'phone' | 'email' | 'firstName' | 'lastName';
  }
>(EventNamespace.QueueSearch);

export const useSettingsTabsTracking = createUseNamespaceTrackingHook<
  EventNamespace.Settings,
  'load' | 'change tab',
  {
    tab:
      | 'Users'
      | 'Locations'
      | 'Providers'
      | 'Practices'
      | 'Groups'
      | 'Custom Questions'
      | 'Unknown';
  }
>(EventNamespace.Settings);

export const useFacesheetModalTracking = createUseNamespaceTrackingHook<
  EventNamespace.FacesheetModal,
  'load' | 'change tab' | 'save' | 'copy' | 'open book followup modal',
  {
    tab?:
      | 'Profile'
      | 'Vaccinations'
      | 'Insurance Details'
      | 'Paperwork'
      | 'Photo ID'
      | 'Consent Forms'
      | 'Lab Results'
      | 'Payments'
      | 'Visit Summary'
      | 'Booking Details'
      | 'Booking History'
      | 'Unknown';
    changedFields?: string[];
    copiedField?: string;
  }
>(EventNamespace.FacesheetModal);

export const usePatientsTracking = createUseNamespaceTrackingHook<
  EventNamespace.Patients,
  'load',
  { tab: 'bookings' | 'accounts' }
>(EventNamespace.Patients);

export const usePaymentsTracking = createUseNamespaceTrackingHook<EventNamespace.Payments, 'load'>(
  EventNamespace.Payments
);

export const useReviewsModerationTracking = createUseNamespaceTrackingHook<
  EventNamespace.Reviews,
  'load',
  { tab: string }
>(EventNamespace.Reviews);

export const useNotificationsTracking = createUseNamespaceTrackingHook<
  EventNamespace.UserEmailNotifications,
  'save',
  { enabledNotifications?: string[] | null }
>(EventNamespace.UserEmailNotifications);

export const useRecentUpdatesTracking = createUseNamespaceTrackingHook<
  EventNamespace.RecentUpdatesFeed,
  | 'load'
  | 'no updates to display'
  | 'feature disabled'
  | 'error fetching'
  | 'success fetching'
  | 'click link'
>(EventNamespace.RecentUpdatesFeed);
